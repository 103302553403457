<template>
    <div>
        <el-row :gutter="10" style=" margin-bottom: 10px;">
            <el-col :span="12">
                <el-card style="color: #409EFF">
                    <div><i class="el-icon-bank-card"/> 待处理付款码</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item1}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card style="color: #F56C6C">
                    <div><i class="el-icon-bank-card"/> 今日付款金额</div>
                    <div style="padding: 10px 0; text-align: center; font-weight: bold">
                        {{item2}}
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-row :gutter="10" style=" margin-bottom: 10px;" class="card1">
            <el-col :span="12">
                <el-card>
                    <el-form label-width="120px" size="small">
                        <el-form-item label="结算单编码:">
                            {{form.orderCode}}
                        </el-form-item>
                        <el-form-item label="会员手机号:">
                            {{form.mobile}}
                        </el-form-item>
                        <el-form-item label="网剧:">
                            {{form.Teleplay?form.Teleplay.title:''}}
                        </el-form-item>
                        <el-form-item label="会员等级:">
                            {{form.levelInfo}}
                        </el-form-item>
                        <el-form-item label="评论:">
                            {{form.comment}}
                        </el-form-item>
                        <el-form-item label="结算状态:">
                            <el-tag v-if="form.state ===1">待处理</el-tag>
                            <el-tag v-if="form.state ===2" type='success'>付款成功</el-tag>
                            <el-tag v-if="form.state ===3" type='danger'>付款失败</el-tag>
                        </el-form-item>
                        <el-form-item label="结算用户:">
                            {{form.clearingUserName}}
                        </el-form-item>
                        <el-form-item label="创建时间:">
                            {{form.createTime}}
                        </el-form-item>

                    </el-form>
                    <div>
                        <!--                    <el-button @click="load">刷新</el-button>-->
                        <el-button type="primary" @click="startPayment" size="medium">开始付款</el-button>
                        <el-button type="primary" @click="endPayment" :disabled="!paymentFlag" size="medium">暂停付款
                        </el-button>
                        <el-button type="success" @click="paymentSuccess" :disabled="!form.id" size="medium">付款成功
                        </el-button>
                        <el-button type="danger" @click="paymentFailed" :disabled="!form.id" size="medium">付款失败
                        </el-button>
                        <el-button type="primary" @click="release" :disabled="!form.id" size="medium">释放当前付款码
                        </el-button>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card style="  display: flex;justify-content: center;">
                    <img :src="form.member && form.member.qrCode? form.member.qrCode : require('@/assets/zanwu.jpg')"
                         class="avatar">
                    <div style="margin: 20px;font-size: 20px;display: flex;justify-content: center;" v-if="form.salary">
                        付款金额：￥ <span style="color:red;">{{form.salary}}</span>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-row :gutter="10" style=" margin-bottom: 10px;" class="card2">
            <el-col :span="24">
                <el-card style="  display: flex;justify-content: center;">
                    <img :src="form.member && form.member.qrCode? form.member.qrCode : require('@/assets/zanwu.jpg')"
                         class="avatar">
                    <div style="margin: 20px;font-size: 20px;display: flex;justify-content: center;" v-if="form.salary">
                        付款金额：￥ <span style="color:red;">{{form.salary}}</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="24">
                <el-card>
                    <div style="margin-bottom: 15px">
                        <el-button type="primary" @click="startPayment" size="mini">开始付款</el-button>
                        <el-button type="primary" @click="endPayment" :disabled="!paymentFlag" size="mini">暂停付款
                        </el-button>
                        <el-button type="primary" @click="release" :disabled="!form.id" size="mini">释放当前付款码</el-button>
                    </div>
                    <div style="margin-bottom: 5px">
                        <el-button type="success" @click="paymentSuccess" :disabled="!form.id" size="medium ">付款成功
                        </el-button>
                        <el-button type="danger" @click="paymentFailed" :disabled="!form.id" size="medium ">付款失败
                        </el-button>
                    </div>
                    <div>
                    </div>
                    <el-form label-width="100px" size="small">
                        <el-form-item label="结算单编码:">
                            {{form.orderCode}}
                        </el-form-item>
                        <el-form-item label="会员手机号:">
                            {{form.mobile}}
                        </el-form-item>
                        <el-form-item label="网剧:">
                            {{form.Teleplay?form.Teleplay.title:''}}
                        </el-form-item>
                        <el-form-item label="会员等级:">
                            {{form.levelInfo}}
                        </el-form-item>
                        <el-form-item label="评论:">
                            {{form.comment}}
                        </el-form-item>
                        <el-form-item label="结算状态:">
                            <el-tag v-if="form.state ===1">待处理</el-tag>
                            <el-tag v-if="form.state ===2" type='success'>付款成功</el-tag>
                            <el-tag v-if="form.state ===3" type='danger'>付款失败</el-tag>
                        </el-form-item>
                        <el-form-item label="结算用户:">
                            {{form.clearingUserName}}
                        </el-form-item>
                        <el-form-item label="创建时间:">
                            {{form.createTime}}
                        </el-form-item>

                    </el-form>

                </el-card>
            </el-col>

        </el-row>

    </div>
</template>

<script>
    export default {
        name: "clearing",
        data() {
            return {
                item1: 0,
                item2: 0,
                form: {},
                paymentFlag: false
            }
        },
        // 钩子函数 页面渲染后加载
        created() {
            this.load()
        },
        // 方法
        methods: {
            load() {
                this.request.get("/memberVote/queryPending").then(res => {
                    this.item1 = res.data.countPending
                    this.item2 = res.data.payAmount
                })
            },
            //开始付款
            startPayment() {
                this.request.get("/memberVote/clearingGain").then(res => {
                    console.log(res)
                    if (res.code === '200') {
                        this.$message.success("获取付款码成功")
                        this.form = res.data
                        this.paymentFlag = true
                    } else {
                        this.form = {}
                        this.paymentFlag = false
                    }
                })
            },
            //暂停付款
            endPayment() {
                this.paymentFlag = false
            },
            //付款成功
            //结算状态，1.待处理，2.付款成功，3.付款失败
            paymentSuccess() {
                this.request.get("/memberVote/clearingComplete", {
                    params: {
                        id: this.form.id,
                        state: 2,
                    }
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success("操作成功")
                        if (this.paymentFlag) {
                            this.startPayment()
                        } else {
                            this.form = {}
                            this.paymentFlag = false
                        }
                    }
                    this.load();
                })

            },
            //付款失败
            paymentFailed() {
                this.request.get("/memberVote/clearingComplete", {
                    params: {
                        id: this.form.id,
                        state: 3,
                    }
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success("操作成功")
                        if (this.paymentFlag) {
                            this.startPayment()
                        } else {
                            this.form = {}
                            this.paymentFlag = false
                        }
                    }
                    this.load();
                })
            },
            //释放当前付款码
            release() {
                this.request.get("/memberVote/clearingRelease", {
                    params: {
                        id: this.form.id,
                    }
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success("操作成功")
                        this.form = {}
                        this.paymentFlag = false
                    }
                    this.load();
                })
            }
        }
    }
</script>

<style scoped>

    .headerBg {
        background: #eee !important;
    }

    .avatar-uploader {
        text-align: left;
        padding-bottom: 10px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 138px;
        height: 138px;
        line-height: 138px;
        text-align: center;
    }

    .avatar {
        width: 400px;
        height: 400px;
        display: block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }


    .card1 {
        display: contents;
    }

    .card2 {
        display: none;
    }

    @media (max-width: 768px) {
        .card1 {
            display: none;
        }

        .card2 {
            display: contents;
        }

        .avatar {
            width: 200px;
            height: 250px;
            display: block;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-form-item {
            margin-bottom: 5px; /* 调整间距 */
        }
    }
</style>
